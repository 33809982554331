import { createSimulationQuestionFromResponse, ISimulationQuestionResponse } from "./simulation-question.response";
import { createSimulationResult, ISimulationResult } from "./simulation-result.model";

export interface ISimulationResultResponse {
    id: number;
    basic_correct: number;
    basic_incorrect: number;
    basic_success_rate: number;
    basic_error_points: number;
    basic_correct_questions: Array<ISimulationQuestionResponse>;
    basic_incorrect_questions: Array<ISimulationQuestionResponse>;
    additional_correct: number;
    additional_incorrect: number;
    additional_success_rate: number;
    additional_error_points: number;
    additional_correct_questions: Array<ISimulationQuestionResponse>;
    additional_incorrect_questions: Array<ISimulationQuestionResponse>;
}

export const createSimulationResultFromResponse = ({
    id,
    basic_correct,
    basic_incorrect,
    basic_success_rate,
    basic_error_points,
    basic_correct_questions,
    basic_incorrect_questions,
    additional_correct,
    additional_incorrect,
    additional_success_rate,
    additional_error_points,
    additional_correct_questions,
    additional_incorrect_questions,
    ...props
}: ISimulationResultResponse): ISimulationResult => {
    return createSimulationResult({
        id: id?.toString(),
        basicCorrect: basic_correct,
        basicIncorrect: basic_incorrect,
        basicSuccessRate: basic_success_rate,
        basicErrorPoints: basic_error_points,
        basicCorrectQuestions: basic_correct_questions?.map(createSimulationQuestionFromResponse) ?? [],
        basicIncorrectQuestions: basic_incorrect_questions?.map(createSimulationQuestionFromResponse) ?? [],
        additionalCorrect: additional_correct,
        additionalIncorrect: additional_incorrect,
        additionalSuccessRate: additional_success_rate,
        additionalErrorPoints: additional_error_points,
        additionalCorrectQuestions: additional_correct_questions?.map(createSimulationQuestionFromResponse) ?? [],
        additionalIncorrectQuestions: additional_incorrect_questions?.map(createSimulationQuestionFromResponse) ?? [],
        ...props,
    });
};
