import { ISimulationQuestion } from "./simulation-question.model";

export interface ISimulationResult {
    id: string;
    basicCorrect: number;
    basicIncorrect: number;
    basicSuccessRate: number;
    basicErrorPoints: number;
    basicCorrectQuestions: Array<ISimulationQuestion>;
    basicIncorrectQuestions: Array<ISimulationQuestion>;
    additionalCorrect: number;
    additionalIncorrect: number;
    additionalSuccessRate: number;
    additionalErrorPoints: number;
    additionalCorrectQuestions: Array<ISimulationQuestion>;
    additionalIncorrectQuestions: Array<ISimulationQuestion>;
}

export const createSimulationResult = ({ id, ...props }: Partial<ISimulationResult>) => {
    if (!id) {
        throw new Error('Unable to create a simulation result without an ID!');
    }

    return {
        id,
        basicCorrect: null,
        basicIncorrect: null,
        basicSuccessRate: null,
        basicErrorPoints: null,
        basicCorrectQuestions: [],
        basicIncorrectQuestions: [],
        additionalCorrect: null,
        additionalIncorrect: null,
        additionalSuccessRate: null,
        additionalErrorPoints: null,
        additionalCorrectQuestions: [],
        additionalIncorrectQuestions: [],
        ...props,
    } as ISimulationResult;
};
