import React, { useLayoutEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import { Box } from "@material-ui/core";
import Button from "core/components/Button";
import Loader from "core/components/Loader";
import { useTranslation } from "core/hooks/use-translation.hook";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useStudents } from "modules/students/hooks/use-students.hook";
import Text from "core/components/Text";
import { useStudentsContext } from "modules/students/hooks/use-students-context.hook";
import { Table } from "core/components/Table";
import { Toolbar } from "modules/home/components/Toolbar";
import { calculateStudentProgress } from "modules/students/utils/calculate-student-progress";
import { formatStudentActivity } from "modules/students/utils/format-student-activity";
import { DateFormat } from "core/enums/date-format.enum";
import { StudentStatus } from "./components/StudentStatus";
import { StudentProgressBar } from "modules/students/components/StudentProgressBar";
import { pathBuilder } from "core/utils/path-builder";
import { ITableHeadCell } from "core/components/Table/TableHead";
import styles from "./styles.module.scss";
import { StudentSortBy } from "modules/students/enums/student-sort-by.enum";
import { SortDirection } from "core/interfaces/search-params.interface";
import { useRouteQueryParams } from "core/hooks/use-route-query-params.hook";
import { StudentStatus as EStudentStatus } from "modules/students/enums/student-status.enum";

interface IStudentTableRow {
    id: string;
    firstName: string;
    lastName: string;
    activity: string;
    estimatedFinishDate: string;
    status: React.ReactNode;
    progress: React.ReactNode;
}

export const StudentsList = () => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const [sortDirection, setSortDirection] = useState(SortDirection.DESC);
    const { activePage, setActivePage } = useStudentsContext();
    const routeQueryParams = useRouteQueryParams();
    const studentStatus = routeQueryParams.get("status") as EStudentStatus;

    const toolbarTitle = useMemo(() => {
        switch (studentStatus) {
            case EStudentStatus.INVITATION_PENDING:
                return t("invitation-pending-students");
            case EStudentStatus.ACTIVE:
                return t("active-students");
            case EStudentStatus.READY_FOR_EXAM:
                return t("exam-ready-students");
            case EStudentStatus.PASSED:
                return t("passed-students");
            default:
                return t("students");
        }
    }, [studentStatus]);

    /**
     * Resets table state on default once table filter (student status) is changed.
     */
    useLayoutEffect(() => {
        activePage !== 1 && setActivePage(1);
        sortDirection !== SortDirection.DESC && setSortDirection(SortDirection.DESC);
    }, [studentStatus]);

    const {
        data,
        isLoading,
        isFetchingNextPage,
    } = useStudents({
        queryKey: [
            "students",
            {
                page: activePage,
                sortBy: [StudentSortBy.PROGRESS, sortDirection],
                /**
                 * * "all" is used as an indicator for the query key meaning that no filter is selected
                 */
                filterBy: studentStatus ?? "all",
            },
        ],
        page: activePage,
        orderBy: StudentSortBy.PROGRESS,
        direction: sortDirection,
        status: studentStatus ?? undefined,
        dashboardStatus: studentStatus === EStudentStatus.PASSED ? EStudentStatus.PASSED : undefined,
    });

    const studentsTableHead: Array<ITableHeadCell> = useMemo(() => ([
        { translateKey: "first-name" },
        { translateKey: "last-name" },
        { translateKey: "last-activity-date" },
        { translateKey: "estimated-finish-date" },
        { translateKey: "status" },
        {
            translateKey: "progress",
            sortable: true,
            sortDirection: sortDirection,
            isActiveColumn: true,
            onClick: () => setSortDirection((prevSortDirection) => {
                return prevSortDirection === SortDirection.DESC
                    ? SortDirection.ASC
                    : SortDirection.DESC;
            }),
        },
    ]), [sortDirection]);

    const studentsTableBody: Array<IStudentTableRow> = useMemo(() => data?.items?.map((student) => {
        const { overallTotal, overallCorrect, overallIncorrect } = student.userProgress ?? {};
        const calculatedStudentProgress = calculateStudentProgress({
            total: overallTotal,
            correct: overallCorrect,
            incorrect: overallIncorrect,
        });

        return {
            id: student.id,
            firstName: student.name,
            lastName: student.familyName,
            activity: formatStudentActivity(student.lastActive),
            estimatedFinishDate: student.examDate ? format(
                new Date(student.examDate),
                DateFormat.ddMMyyyy
            ) : "-",
            status: <StudentStatus status={student.status} />,
            progress: <StudentProgressBar
                correctAnswers={calculatedStudentProgress.correctAnswers}
                wrongAnswers={calculatedStudentProgress.wrongAnswers}
                showProgressCount
                className={styles.studentProgressBar}
            />,
        };
    }) ?? [], [data]);

    return (
        <>
            <Toolbar
                title={toolbarTitle}
                actions={
                    <Link to={ROUTES.ADD_STUDENT}>
                        <Button
                            label={t("add-new-student")}
                            style={{ width: "fit-content" }}
                        />
                    </Link>
                }
            />

            {isLoading ? (
                <Loader />
            ) : data?.items?.length > 0 ? (
                <Table<IStudentTableRow>
                    headProps={{
                        data: studentsTableHead,
                    }}
                    bodyProps={{
                        data: studentsTableBody,
                        onRowClick: (id: string) => {
                            push(
                                pathBuilder(
                                    ROUTES.STUDENT_PROFILE,
                                    ":studentId",
                                    id,
                                )
                            );
                        },
                    }}
                    paginationProps={{
                        page: activePage,
                        lastActivePage: data.currentPage,
                        pageSize: data.items.length,
                        totalPages: data.totalPages,
                        totalResults: data.totalResults,
                        isFetchingNextPage,
                        setPage: setActivePage,
                    }}
                />
            ) : (
                <Box mt="32px">
                    <Text>{t("no-data-available")}</Text>
                </Box>
            )}
        </>
    );
}
