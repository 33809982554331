import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@material-ui/core/Box";
import DatePicker from "core/components/DatePicker";
import FormInput from "core/components/FormInput";
import FormWrapper from "core/components/FormWrapper";
import { DateFormat } from "core/enums/date-format.enum";
import { FormInputType } from "core/enums/form-input-type.enum";
import { useTranslation } from "core/hooks/use-translation.hook";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useAddStudentValidationSchema } from "modules/students/hooks/use-form-validation-schema.hook";
import { defaultAddStudentValues, IAddStudentCredentials } from "modules/students/models/add-student.model";
import { useAddStudent } from "modules/students/hooks/use-add-student.hook";
import { useSnackbar } from "core/hooks/use-snackbar.hook";
import { DateOfBirthController } from "./components/DateOfBirthController";
import { GenderController } from "./components/GenderController";
import { IStudent } from "core/models/student/student.model";

export const AddStudent = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const addStudentValidationSchema = useAddStudentValidationSchema();
    const {
        handleSubmit,
        control,
        formState: { isValid, errors },
        trigger,
        setValue,
        getValues,
        clearErrors,
    } = useForm<IAddStudentCredentials>({
        mode: "onChange",
        resolver: yupResolver(addStudentValidationSchema),
        defaultValues: defaultAddStudentValues,
    });

    const {
        addStudent,
        isLoading,
        serverErrorMessage,
    } = useAddStudent();
    const { setSnackbarProps, clearSnackbarProps } = useSnackbar();

    const onSubmit = async (data: IAddStudentCredentials) => {
        clearSnackbarProps();
        const student: IStudent = await addStudent(data);

        if (!student) return;

        push(ROUTES.STUDENTS);
        setSnackbarProps({
            open: true,
            message: t("invitation-sent"),
            alertType: "success",
            onClose: clearSnackbarProps,
        });
    };

    return (
        <FormWrapper
            title={t("add-new-student")}
            subTitle={t("fill-form-fields-to-add-student")}
            backToPath={ROUTES.STUDENTS}
            submitButtonProps={{
                label: t("send-invitation"),
                onClick: handleSubmit(onSubmit),
                disabled: !isValid || isLoading,
                isLoading: isLoading,
            }}
            error={serverErrorMessage}
        >
            <Box display="grid" flexDirection="column" gap={2}>
                <Controller
                    name="first_name"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<IAddStudentCredentials>
                            inputRef={ref}
                            label={t("student-name")}
                            type={FormInputType.TEXT}
                            placeholder={t("student-name-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="last_name"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<IAddStudentCredentials>
                            inputRef={ref}
                            label={t("student-last-name")}
                            type={FormInputType.TEXT}
                            placeholder={t("student-last-name-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <FormInput<IAddStudentCredentials>
                            inputRef={ref}
                            label={t("student-email-address")}
                            type={FormInputType.TEXT}
                            placeholder={t("student-email-address-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <DateOfBirthController
                    control={control}
                    serverErrorMessage={serverErrorMessage}
                    hasErrors={!!(errors?.day || errors?.month || errors?.year)}
                />
                <GenderController
                    control={control}
                    serverErrorMessage={serverErrorMessage}
                    validate={(fieldName: string) => trigger(fieldName as keyof IAddStudentCredentials)}
                    getFormValues={getValues}
                    setValue={setValue}
                    clearErrors={clearErrors}
                />
                <Controller
                    name="start_date"
                    control={control}
                    render={({field: { ref, ...restFieldProps }, fieldState: { error }}) => (
                        <DatePicker<IAddStudentCredentials>
                            refCallback={ref}
                            label={t("start-date")}
                            type={FormInputType.TEXT}
                            placeholder={DateFormat.ddMMyyyy}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
            </Box>
        </FormWrapper>
    );
}
