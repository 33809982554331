import React, { FC } from "react";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DateFnsAdapter from "@material-ui/lab/AdapterDateFns";
import locale from "date-fns/locale/en-US";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import colors from "styles/variables/colors.module.scss";
import { RGBAColors } from "styles/variables/rgba-colors";

if (locale?.options) {
    locale.options.weekStartsOn = 1;
}

export const StyledDatePicker: FC = ({ children }) => {
    const theme = React.useMemo(() => {
        const baseTheme = createTheme({
            shape: {
                borderRadius: 4,
            },
            typography: {
                fontFamily: "NunitoSansRegular",
            },
        });

        return createTheme(baseTheme, {
            components: {
                MuiCalendarPicker: {
                    styleOverrides: {
                        root: {
                            '[role="presentation"]': {
                                position: "absolute",
                                right: "100px",
                                fontSize: "14px",
                            },
                            '[role="presentation"] + div': {
                                width: "100%",
                                justifyContent: "space-between",
                            },
                        },
                    },
                },
                MuiYearPicker: {
                    styleOverrides: {
                        root: {
                            '> div > button': {
                                '&.Mui-selected': {
                                    backgroundColor: `${colors.violet} !important`,
                                    color: `${colors.white} !important`,
                                },
                                '&:hover': {
                                    backgroundColor: RGBAColors.lightVioletWithOpacity(0.08),
                                },
                                '&.Mui-disabled:hover': {
                                    backgroundColor: "transparent",
                                    cursor: "default",
                                },
                            },
                        },
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableTouchRipple: true,
                    },
                    styleOverrides: {
                        root: {
                            fontSize: "14px !important",
                        },
                    },
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            color: colors.violet,
                            '&.Mui-disabled': {
                                color: RGBAColors.lightVioletWithOpacity(0.5),
                            },
                            '&:hover': {
                                backgroundColor: "transparent",
                            },
                        },
                        edgeEnd: {
                            marginRight: "0px",
                        },
                    },
                },
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            color: `${colors.violet} !important`,
                            fontSize: "14px",
                        },
                    },
                },
                MuiPickersDay: {
                    styleOverrides: {
                        root: {
                            '&.Mui-selected': {
                                backgroundColor: `${colors.violet} !important`,
                                color: `${colors.white} !important`,
                            },
                            '&.Mui-disabled': {
                                color: RGBAColors.lightBlackWithOpacity(0.38),
                            },
                            '&:hover': {
                                backgroundColor: RGBAColors.lightVioletWithOpacity(0.08),
                            },
                        },
                        today: {
                            '&:not(.Mui-selected)': {
                                border: "none",
                                backgroundColor: RGBAColors.lightVioletWithOpacity(0.08),
                            },
                        },
                        dayWithMargin: {
                            color: RGBAColors.lightBlackWithOpacity(0.38),
                        },
                        dayOutsideMonth: {
                            color: colors.lightBlack,
                        },
                    },
                },
                MuiInputAdornment: {
                    styleOverrides: {
                        root: {
                            marginLeft: "0px",
                        },
                    },
                },
            },
        });
    }, []);

    return (
        <LocalizationProvider
            dateAdapter={DateFnsAdapter}
            locale={locale}
        >
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </LocalizationProvider>
    );
}
