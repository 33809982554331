import { DateFormat } from "core/enums/date-format.enum";
import { format } from "date-fns";
import { SimulationStatus } from "modules/students/enums/simulation-status.enum";
import { ISearch } from "../search/search.model";
import { createSearchModelFromResponse, ISearchResponse } from "../search/search.response";
import { createSimulationResultFromResponse, ISimulationResultResponse } from "./simulation-result.response";
import { createSimulation, ISimulation } from "./simulation.model";

export interface ISimulationResponse {
    id: number;
    status: string;
    created_at: string;
    completed_at: string;
    result: ISimulationResultResponse;
}

export const createSimulationFromResponse = ({
    id,
    status,
    created_at,
    completed_at,
    result,
    ...props
}: ISimulationResponse): ISimulation => {
    return createSimulation({
        id: id?.toString(),
        status: status as SimulationStatus,
        createdAt: created_at ? format(new Date(created_at), DateFormat.ddMMyyyy) : "",
        completedAt: completed_at ? format(new Date(completed_at), DateFormat.ddMMyyyy) : "",
        result: result ? createSimulationResultFromResponse(result) : null,
        ...props,
    });
};

export const createSimulationsFromResponse = ({
    items,
    ...props
}: ISearchResponse<ISimulationResponse>): ISearch<ISimulation> => {
    return createSearchModelFromResponse({
        items: items?.map(createSimulationFromResponse) ?? [],
        ...props,
    });
};
