import { createDate, formatDateForBackend } from "core/utils/date-handler";
import { StudentStatus } from "modules/students/enums/student-status.enum"
import { IAddStudentCredentials } from "modules/students/models/add-student.model"
import { IEditStudentCredentials } from "modules/students/models/edit-student.model";
import { IStudentResponse } from "./student.response";

export type IAddStudentCredentialsCommand = Pick<
    IStudentResponse,
    "name" | "family_name" | "email" | "started_at" | "status" | "gender" | "day_of_birth"
>;

export const createAddStudentCommand = (
    data: IAddStudentCredentials
): IAddStudentCredentialsCommand => {
    const dateOfBirth = createDate(
        data.day,
        data.month,
        data.year,
    );

    return {
        name: data.first_name,
        family_name: data.last_name,
        email: data.email,
        started_at: formatDateForBackend(data.start_date),
        status: StudentStatus.INVITATION_PENDING,
        gender: data.custom_gender,
        day_of_birth: formatDateForBackend(dateOfBirth),
    };
}

export type IEditStudentCredentialsCommand = Pick<
    IStudentResponse,
    "name" | "family_name" | "started_at" | "gender" | "day_of_birth"
>;

export const createEditStudentCommand = (
    data: IEditStudentCredentials
): IEditStudentCredentialsCommand => {
    const dateOfBirth = createDate(
        data.day,
        data.month,
        data.year,
    );

    return {
        name: data.first_name,
        family_name: data.last_name,
        started_at: formatDateForBackend(data.start_date),
        gender: data.custom_gender,
        day_of_birth: formatDateForBackend(dateOfBirth),
    };
}
