import React, { useMemo } from "react";
import { pathBuilder } from "core/utils/path-builder";
import { Toolbar } from "modules/home/components/Toolbar";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Text from "core/components/Text";
import { useTranslation } from "core/hooks/use-translation.hook";
import { RGBAColors } from "styles/variables/rgba-colors";
import colors from "styles/variables/colors.module.scss";
import styles from "./styles.module.scss";
import { QuestionMaterialCard } from "./QuestionMaterialCard";
import { useSimulation } from "modules/students/hooks/use-simulation.hook";
import Loader from "core/components/Loader";
import { SimulationStatus } from "modules/students/enums/simulation-status.enum";
import { useIsSharedRoute } from "core/pages/SharedStudentProfile/hooks/use-is-shared-route.hook";
import { SHARED_ROUTES } from "core/pages/SharedStudentProfile/enums/app-routes.enum";
import { IStudentParams } from "core/interfaces/student-params.interface";

export const SimulatorProgressItemDetails = () => {
    const { studentId, studentToken, simulationId } = useParams<IStudentParams & { simulationId: string }>();
    const { t } = useTranslation();
    const { isSharedRoute } = useIsSharedRoute();

    const {
        data,
        isLoading,
    } = useSimulation({
        simulationId,
        studentToken,
    });

    const simulationDetails = useMemo(
        () => {
            if (isLoading || !data?.result) return;

            const {
                createdAt,
                status,
                result,
            } = data;

            const {
                basicErrorPoints,
                basicCorrectQuestions,
                basicIncorrectQuestions,
                additionalErrorPoints,
                additionalCorrectQuestions,
                additionalIncorrectQuestions,
            } = result;

            const totalErrorPoints = basicErrorPoints + additionalErrorPoints;
            const totalBasicQuestions = basicCorrectQuestions.length + basicIncorrectQuestions.length;
            const totalAdditionalQuestions = additionalCorrectQuestions.length + additionalIncorrectQuestions.length;

            return {
                createdAt,
                isPassed: status === SimulationStatus.PASSED,
                totalErrorPoints,
                totalBasicQuestions,
                totalAdditionalQuestions,
                ...result,
            };
        },
        [data, isLoading]
    );

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : simulationDetails ? (
                <>
                    <Box mb="24px">
                        <Toolbar
                            title={simulationDetails.createdAt}
                            backToPath={(isSharedRoute && studentToken) ? pathBuilder(
                                SHARED_ROUTES.STUDENT_SIMULATOR_PROGRESS,
                                ":studentToken",
                                studentToken,
                            ) as SHARED_ROUTES : pathBuilder(
                                ROUTES.STUDENT_SIMULATOR_PROGRESS,
                                ":studentId",
                                studentId,
                            ) as ROUTES}
                        />
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        gap={4}
                    >
                        <Box className={styles.errorPointsContainer}>
                            <Text
                                size="small"
                                style={{
                                    color: RGBAColors.lightBlackWithOpacity(0.6),
                                }}
                            >
                                {t("error-points")}
                            </Text>
                            <Text bold>{simulationDetails.totalErrorPoints}</Text>
                        </Box>

                        <Box
                            className={styles.resultContainer}
                            style={{
                                backgroundColor: simulationDetails.isPassed
                                    ? colors.green
                                    : colors.lightRed,
                            }}
                        >
                            <Text size="small" color="white">
                                {t("result")}
                            </Text>
                            <Text bold color="white">
                                {simulationDetails.isPassed ? t("passed") : t("failed")}
                            </Text>
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        flexWrap="wrap"
                        mt="24px"
                        gap={4}
                    >
                        <QuestionMaterialCard
                            title={t("basic-material")}
                            answeredQuestions={simulationDetails.basicCorrect}
                            totalQuestions={simulationDetails.totalBasicQuestions}
                            errorPoints={simulationDetails.basicErrorPoints}
                        />
                        <QuestionMaterialCard
                            title={t("additional-material")}
                            answeredQuestions={simulationDetails.additionalCorrect}
                            totalQuestions={simulationDetails.totalAdditionalQuestions}
                            errorPoints={simulationDetails.additionalErrorPoints}
                        />
                    </Box>
                </>
            ) : (
                <Text>{t("no-data-available")}</Text>
            )}
        </>
    );
}
