import { LocalStorageKeys } from "core/enums/local-storage-keys.enum";
import { UserRole } from "core/enums/user-roles.enum";
import { IToken } from "core/interfaces/token-response.interface";
import { getItemFromLocalStorage } from "core/utils/local-storage-handler";
import { IDrivingSchool } from "../driving-school/driving-school.model";

export type TUser = IToken & { user: IUser };

export interface IUser {
    id: string;
    name: string;
    familyName: string;
    email: string;
    emailVerified: boolean;
    active: boolean;
    sub: string;
    roles: Array<UserRole>;
    drivingSchool: IDrivingSchool;
    code: string;
}

export const createUser = ({ id, ...props }: Partial<IUser>) => {
    if (!id) {
        throw new Error('Unable to create a user without an ID!');
    }

    return {
        id,
        name: "",
        familyName: "",
        email: "",
        emailVerified: false,
        active: false,
        sub: "",
        roles: [],
        drivingSchool: null,
        code: "",
        ...props,
    } as IUser;
};

export const getUserFullName = ({ name, familyName }: IUser) => {
    return [name, familyName]
        .filter((item) => item)
        .join(" ");
}

export const getUserRole = (user: IUser): UserRole => {
    const { role } = getItemFromLocalStorage(LocalStorageKeys.user) ?? {
        role: null,
    };

    return user ? user.roles[0] : role;
};

export const getUserNameBasedOnRole = (user: IUser) => {
    if (getUserRole(user) === UserRole.ROLE_INSTRUCTOR) {
        return user.drivingSchool?.name ?? "";
    }

    return getUserFullName(user);
}
