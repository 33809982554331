import React from "react";
import { TextFieldProps } from "@material-ui/core";
import FormInput, { IFormInputProps } from "../FormInput";
import DesktopDatePicker from '@material-ui/lab/DesktopDatePicker';
import { StyledDatePicker } from "./components/StyledDatePicker";
import { CalendarIcon } from "assets/icons";

export interface IDatePickerProps<T> extends IFormInputProps<T> {
    refCallback: React.RefCallback<HTMLInputElement>;
}

export function DatePicker<T = any>({
    label,
    name,
    type,
    placeholder,
    error,
    refCallback,
    ...restFieldProps
}: IDatePickerProps<T>) {
    return (
        <StyledDatePicker>
            <DesktopDatePicker
                ref={refCallback}
                inputFormat={placeholder}
                disableMaskedInput
                showDaysOutsideCurrentMonth
                renderInput={({ inputRef, inputProps, InputProps }: TextFieldProps) => (
                    <FormInput<T>
                        inputRef={inputRef}
                        label={label}
                        name={name as keyof T}
                        type={type}
                        placeholder={placeholder}
                        error={error}
                        value={inputProps.value}
                        onChange={inputProps.onChange}
                        onFocus={inputProps.onFocus}
                        onBlur={inputProps.onBlur}
                        endAdornment={{
                            showAdornment: true,
                            AdornmentIcon: InputProps?.endAdornment,
                        }}
                        autoComplete="off"
                    />
                )}
                components={{
                    SwitchViewIcon: () => <></>,
                    OpenPickerIcon: () => <CalendarIcon style={{
                        width: "22px",
                        height: "22px",
                    }}/>,
                }}
                PopperProps={{
                    placement: "bottom-end",
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [48, 15],
                            },
                        },
                    ],
                }}
                {...restFieldProps}
            />
        </StyledDatePicker>
    );
}
