export interface ISimulationQuestion {
    officialNumber: string;
    points: number;
}

export const createSimulationQuestion = ({ ...props }: Partial<ISimulationQuestion>) => {
    return {
        officialNumber: "",
        points: null,
        ...props,
    } as ISimulationQuestion;
};
