import { createSimulationQuestion, ISimulationQuestion } from "./simulation-question.model";

export interface ISimulationQuestionResponse {
    official_number: string;
    points: number;
}

export const createSimulationQuestionFromResponse = ({
    official_number,
    ...props
}: ISimulationQuestionResponse): ISimulationQuestion => {
    return createSimulationQuestion({
        officialNumber: official_number ?? "",
        ...props,
    });
};
