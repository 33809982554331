import { DateFormat } from "core/enums/date-format.enum";
import format from "date-fns/format";
import { StudentStatus } from "modules/students/enums/student-status.enum";
import { createDrivingSchoolFromResponse, IDrivingSchoolResponse } from "../driving-school/driving-school.response";
import { ISearch } from "../search/search.model";
import { createSearchModelFromResponse, ISearchResponse } from "../search/search.response";
import { IUserResponse } from "../user/user.response";
import { createStudentProgressFromResponse, IStudentProgressResponse } from "./student-progress.response";
import { IStudent, createStudent } from "./student.model";

export interface IStudentResponse extends Omit<IUserResponse, "email_verified" | "sub"> {
    marked_questions: Array<any>;
    started_at: string;
    status: string;
    gender: string;
    exam_date: string;
    day_of_birth: string;
    user_progress: IStudentProgressResponse;
    last_active: string;
    media: string;
    invitation_code: string;
    total_watched_lessons: number;
    driving_school: IDrivingSchoolResponse;
    overall_progress: number;
}

export const createStudentFromResponse = ({
    id,
    family_name,
    marked_questions,
    started_at,
    status,
    exam_date,
    day_of_birth,
    user_progress,
    last_active,
    invitation_code,
    total_watched_lessons,
    driving_school,
    overall_progress,
    ...props
}: IStudentResponse): IStudent => {
    return createStudent({
        id: id?.toString(),
        familyName: family_name ?? "",
        markedQuestions: marked_questions ?? [],
        startedAt: started_at ? format(new Date(started_at), DateFormat.ddMMyyyy) : "",
        status: status as StudentStatus,
        examDate: exam_date,
        dayOfBirth: day_of_birth ? format(new Date(day_of_birth), DateFormat.ddMMyyyy) : "",
        userProgress: user_progress ? createStudentProgressFromResponse(user_progress) : null,
        lastActive: last_active,
        invitationCode: invitation_code,
        totalWatchedLessons: total_watched_lessons,
        drivingSchool: driving_school ? createDrivingSchoolFromResponse(driving_school) : null,
        overallProgress: overall_progress,
        ...props,
    });
};

export const createStudentsFromResponse = ({
    items,
    ...props
}: ISearchResponse<IStudentResponse>): ISearch<IStudent> => {
    return createSearchModelFromResponse({
        items: items?.map(createStudentFromResponse) ?? [],
        ...props,
    });
};
