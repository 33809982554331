import { SimulationStatus } from "modules/students/enums/simulation-status.enum";
import { ISimulationResult } from "./simulation-result.model";

export interface ISimulation {
    id: string;
    status: SimulationStatus;
    createdAt: string;
    completedAt: string;
    result: ISimulationResult;
}

export const createSimulation = ({ id, ...props }: Partial<ISimulation>) => {
    if (!id) {
        throw new Error('Unable to create a simulation without an ID!');
    }

    return {
        id,
        status: "",
        createdAt: "",
        completedAt: "",
        result: null,
        ...props,
    } as ISimulation;
};
