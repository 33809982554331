import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import parse from "date-fns/parse";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@material-ui/core";
import DatePicker from "core/components/DatePicker";
import FormInput from "core/components/FormInput";
import FormWrapper from "core/components/FormWrapper";
import { DateFormat } from "core/enums/date-format.enum";
import { FormInputType } from "core/enums/form-input-type.enum";
import { useTranslation } from "core/hooks/use-translation.hook";
import { extractDate } from "core/utils/date-handler";
import { pathBuilder } from "core/utils/path-builder";
import { ROUTES } from "modules/home/enums/routes.enum";
import { useEditStudent } from "modules/students/hooks/use-edit-student.hook";
import { useEditStudentValidationSchema } from "modules/students/hooks/use-form-validation-schema.hook";
import { useStudent } from "modules/students/hooks/use-student.hook";
import {
    defaultEditStudentValues,
    IEditStudentCredentials,
} from "modules/students/models/edit-student.model";
import { DateOfBirthController } from "../AddStudent/components/DateOfBirthController";
import { GenderController } from "../AddStudent/components/GenderController";
import { DeleteStudentButton } from "./components/DeleteStudentButton";
import { IStudentParams } from "core/interfaces/student-params.interface";

export const EditStudent = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { studentId } = useParams<IStudentParams>();

    const editStudentValidationSchema = useEditStudentValidationSchema();
    const {
        handleSubmit,
        control,
        formState: { isValid, errors },
        setValue,
        trigger,
        clearErrors,
        getValues,
    } = useForm<IEditStudentCredentials>({
        mode: "onChange",
        resolver: yupResolver(editStudentValidationSchema),
        defaultValues: defaultEditStudentValues,
    });

    const { data: studentData, isLoading: isStudentLoading } = useStudent({
        studentId,
    });

    useEffect(() => {
        if (!studentData || isStudentLoading) return;

        const { day, month, year } = extractDate(studentData.dayOfBirth);

        setValue("first_name", studentData.name);
        setValue("last_name", studentData.familyName);
        setValue("day", day);
        setValue("month", month);
        setValue("year", year);
        setValue("gender", studentData.gender);
        // Date picker doesn't accept "dd/MM/yyyy" format, so we need to parse it as new Date object and get string value
        setValue(
            "start_date",
            parse(
                studentData.startedAt,
                DateFormat.ddMMyyyy,
                new Date()
            ).toDateString()
        );

        trigger([
            "first_name",
            "last_name",
            "day",
            "month",
            "year",
            "gender",
            "start_date",
        ]);
    }, [studentData, isStudentLoading]);

    const { editStudent, isLoading, serverErrorMessage } = useEditStudent({
        studentId,
    });

    const onSubmit = async (data: IEditStudentCredentials) => {
        if (await editStudent(data)) {
            push(pathBuilder(ROUTES.STUDENT_PROFILE, ":studentId", studentId));
        }
    };

    return (
        <FormWrapper
            title={t("edit-entity", { entityName: t("student") })}
            subTitle={t("fill-form-fields-to-edit-student")}
            backToPath={
                pathBuilder(
                    ROUTES.STUDENT_PROFILE,
                    ":studentId",
                    studentId
                ) as ROUTES
            }
            submitButtonProps={{
                label: t("save-changes"),
                onClick: handleSubmit(onSubmit),
                disabled: !isValid || isLoading,
                isLoading: isLoading || isStudentLoading,
            }}
            error={serverErrorMessage}
            actions={
                <DeleteStudentButton
                    studentId={studentId}
                    // disabled={isStudentLoading || !studentData?.active}
                    disabled={isStudentLoading}
                />
            }
        >
            <Box display="grid" flexDirection="column" gap={2}>
                <Controller
                    name="first_name"
                    control={control}
                    render={({
                        field: { ref, ...restFieldProps },
                        fieldState: { error },
                    }) => (
                        <FormInput<IEditStudentCredentials>
                            inputRef={ref}
                            label={t("student-name")}
                            type={FormInputType.TEXT}
                            placeholder={t("student-name-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <Controller
                    name="last_name"
                    control={control}
                    render={({
                        field: { ref, ...restFieldProps },
                        fieldState: { error },
                    }) => (
                        <FormInput<IEditStudentCredentials>
                            inputRef={ref}
                            label={t("student-last-name")}
                            type={FormInputType.TEXT}
                            placeholder={t("student-last-name-placeholder")}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
                <DateOfBirthController
                    control={control}
                    serverErrorMessage={serverErrorMessage}
                    hasErrors={!!(errors?.day || errors?.month || errors?.year)}
                />
                <GenderController
                    control={control}
                    serverErrorMessage={serverErrorMessage}
                    validate={(fieldName: string) =>
                        trigger(fieldName as keyof IEditStudentCredentials)
                    }
                    getFormValues={getValues}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    defaultValue={studentData?.gender}
                />
                <Controller
                    name="start_date"
                    control={control}
                    render={({
                        field: { ref, ...restFieldProps },
                        fieldState: { error },
                    }) => (
                        <DatePicker<IEditStudentCredentials>
                            refCallback={ref}
                            label={t("start-date")}
                            type={FormInputType.TEXT}
                            placeholder={DateFormat.ddMMyyyy}
                            error={error?.message || !!serverErrorMessage}
                            {...restFieldProps}
                        />
                    )}
                />
            </Box>
        </FormWrapper>
    );
};
