import { IUser } from "../user/user.model";
import { StudentStatus } from "modules/students/enums/student-status.enum";
import { IStudentProgress } from "./student-progress.model";
import { IDrivingSchool } from "../driving-school/driving-school.model";

export interface IStudent extends Omit<IUser, "emailVerified" | "sub"> {
    markedQuestions: Array<any>;
    startedAt: string;
    status: StudentStatus;
    gender: string;
    examDate: string;
    dayOfBirth: string;
    userProgress: IStudentProgress;
    lastActive: string;
    media: string;
    invitationCode: string;
    totalWatchedLessons: number;
    drivingSchool: IDrivingSchool;
    overallProgress: number;
}

export const createStudent = ({ id, ...props }: Partial<IStudent>) => {
    if (!id) {
        throw new Error('Unable to create a student without an ID!');
    }

    return {
        id,
        name: "",
        familyName: "",
        email: "",
        roles: [],
        markedQuestions: [],
        startedAt: "",
        status: "",
        active: false,
        gender: "",
        examDate: "",
        dayOfBirth: "",
        userProgress: null,
        lastActive: "",
        media: "",
        invitationCode: null,
        totalWatchedLessons: null,
        drivingSchool: null,
        overallProgress: null,
        code: "",
        ...props,
    } as IStudent;
};

export const getStudentFullName = ({ name, familyName }: IStudent) => {
    return [name, familyName]
        .filter((item) => item)
        .join(" ");
}
